import { instance } from '@/utils/http.js'

//复习讲义页面-根据科目id获取知识点分类
export const getCategory = function (params) {
  return instance({
    url: '/api/v1/lecture/get_category',
    method: 'get',
    params
  })
}
//复习讲义页面-根据知识点分类id（和关键字）获取讲义列表
export const getHandoutList = function (params) {
  return instance({
    url: '/api/v1/lecture/lst',
    method: 'get',
    params
  })
}


//复习讲义页面-根据讲义id获取讲义详情（打开讲义)
export const getLecture = function (params) {
  return instance({
    url: '/api/v1/lecture/get_lecture',
    method: 'get',
    params
  })
}