<template>
  <div>
    <div class="review-handout-container"
         :style="route.path == '/course/reviewHandout' ? '' : 'background: rgb(22, 92, 238);'">

      <!-- 上部导航栏 -->
      <img class="return-button"
           src="@/static/icon-back.png"
           alt=""
           @click="handleReturn" />
      <div class="top-tab-bar">
        <div class="subjecTabs">
          <div :class="{ item: true, active: currentSubjectIndex === index}"
               v-for="(item, index) in subjectList"
               @click="checkStatus(index)"
               :key="index">
            {{ item.subject_name }}
          </div>
          <!-- <div class="search">
            <img src="@/static/baisearch.png"
                 @click="showDialog"
                 alt="">
          </div> -->
        </div>
      </div>

      <!-- 弹窗 -->
      <!-- <formDialog ref="searchDialogRef"
                  @getData='getHandoutData' /> -->

      <!-- 内容 -->
      <div class="content-container">
        <!-- {{$route.path == '/reviewHandout'?'50rem 0 0 40rem !important;':'50rem 0 0 80rem !important;'}} -->
        <!--  :style="$route.path == '/course/reviewHandout'? 'margin:30rem 0 0 40rem !important;': 'margin:30rem 0 0 80rem !important;'" -->
        <!-- tab bar -->
        <div class="left-tab-bar bg">
          <div class="tab "
               v-for="(item, index) of lectureCategoryList"
               :key="index + '$0'"
               :class="index === currentTabIndex ? 'active' : ''"
               @click="handleToggleTab(index)">
            <p class="name line-clamp-1">{{ item.name }}</p>
          </div>
        </div>
        <!-- content -->
        <div class="resource">
          <div v-for='(item,index) in handoutListArr'
               class="subject-card"
               :key='index'>
            <detialCard :content="item"
                        @handleEmit='handleSubjectCardClick'
                        :options="subjectCardOptions" />
          </div>
          <div class="subject-card"></div><!-- 占位 -->
          <div class="subject-card"></div><!-- 占位 -->
          <div class="subject-card"></div><!-- 占位 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import detialCard from './components/detailCard.vue'
import { getCategory, getHandoutList, getLecture } from '@/api/reviewHandout.js'
import { instance } from '@/utils/http.js'
import formDialog from '@/views/components/COMMON_TOP_SEARCH/search.vue'
import { getUserSubject } from '@/api/subject.js'
import { useStore } from 'vuex'
const route = useRoute()
const router = useRouter()
const store = useStore()
let searchDialogRef = ref()
let currentSubjectIndex = ref(0)
let schoolId = ref(0)
let subjectList = ref([])
let lectureCategoryList = ref([])//知识点分类列表
let currentTabIndex = ref(0)
let handoutListArr = ref([])//讲义列表
let subjectCardOptions = {
  operationTitle: '讲义详情',
  colorList: {
    0: {
      background: '#d2ecf9',
      textcolor: '#0D2C78',
    },
    1: {
      background: '#DED2F9',
      textcolor: '#2B0D78',
    },
    2: {
      background: '#F9DED2',
      textcolor: '#78390D',
    },
    3: {
      background: '#F9F1D2',
      textcolor: '#784B0D',
    },
    4: {
      background: '#d2f9f4',
      textcolor: '#0D7866',
    },
    5: {
      background: '#D2DCF9',
      textcolor: '#0D2878',
    },
  },
}

onMounted(async () => {
  schoolId.value = JSON.parse(
    window.localStorage.getItem('userInfo')
  ).edu_school_id;
  getSubject()
})

subjectList.value = store.state.subject.subjectList
const getSubject = async () => {
  if (!subjectList.value.length) {
    const { data } = await getUserSubject()
    subjectList.value = data.list
    store.commit('subject/setSubjectList', data.list)
  }
  //console.log(subjectList)
  checkStatus(0)
}

const checkStatus = (index) => {
  currentSubjectIndex.value = index;
  // keywords.value = '';
  const subject_id = subjectList.value[index].subject_id;
  getPaperList(subject_id);
}

//根据科目id获取知识点分类
const getPaperList = async (subject_id) => {
  handoutListArr.value = []
  const lectureCategoryData = await getCategory({
    subject_id
  })
  lectureCategoryList.value = lectureCategoryData.data.map((item) => {
    item.id = item.lecture_category_id;
    item.name = item.lecture_category_name;
    return item;
  });
  if (lectureCategoryList.value.length) handleToggleTab(0);
}
// 切割数组
/*const cutArray = (array, subLength) => {
  let index = 0;
  let newArr = [];
  while (index < array.length) {
    newArr.push(array.slice(index, (index += subLength)));
  }
  console.log(newArr)
  return newArr;
}*/
let category_id = ref('')
const handleToggleTab = (index) => {
  currentTabIndex.value = index;
  const id = lectureCategoryList.value[index].id;
  category_id = id
  getHandoutData();
}
//根据知识点分类id（和关键字）获取讲义列表
const getHandoutData = async (keywords) => {
  const { data: handoutData } = await getHandoutList({
    category: category_id, keywords
  })
  handoutData.map((item, index) => {
    item.id = item.lecture_id;
    item.title = item.lecture_name;
    item.colorId = index % 6;
    item.subject = item.subject_name;
  });
  // handoutListArr.value = cutArray(handoutData, 3)
  handoutListArr.value = handoutData
}

//根据讲义id获取讲义详情（打开讲义)
const handleSubjectCardClick = async (lecture_id) => {
  const { data: handoutInfo } = await getLecture({
    lecture_id
  })
  const url = handoutInfo.file_url;
  // 打开新页面并展示pdf
  window.open(url);

  // router.push('/course/preview?type=document&url=' + url)

}
const navigateTo = (path, query) => {
  router.push({ path, query });
}
const handleReturn = () => {
  router.go(-1);
}
const showDialog = () => {
  searchDialogRef.value.dialogVisible = true;
}

</script>

<style lang="scss" scoped>
.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    height: auto;
    overflow: visible;
    white-space: normal;
  }
}

.review-handout-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url(../../static/allBackg/bg-复习讲义.png);
  background-size: 100% 100%;
  // background-size: cover;
  // background-attachment: fixed;
  .return-button {
    width: 60rem;
    height: 60rem;
    margin-left: 40rem;
    margin-top: 30rem;
    cursor: pointer;
  }
  .top-tab-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 30rem;
    border-radius: 33rem;
    .subjecTabs {
      flex: 1;
      background-color: #303fd4;
      height: 80rem;
      color: #e5e5e5;
      font-weight: 500;

      display: flex;
      justify-content: center;
      border-radius: 33rem;
      position: relative;
      margin: 0 120rem;
      .item {
        cursor: pointer;
        margin-right: 25rem;
        margin-left: 20rem;
        font-size: 28rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .search {
        width: 36rem;
        height: 36rem;
        position: absolute;
        right: 40rem;
        bottom: 22rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 36rem;
          height: 36rem;
        }
      }
      .active {
        color: #ffffff !important;
        font-weight: bold;
      }
    }
  }
  .content-container {
    display: flex;
    margin-top: 10rem;
    .left-tab-bar {
      width: 420rem;
      height: calc(100vh - 275rem);
      padding: 13rem;
      background: #1421a3;
      border: 10rem solid #7f97ff;
      border-radius: 30rem;
      margin-top: 40rem;
      margin-left: 40rem;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .tab {
      line-height: 40rem;
      padding: 20rem 0;
      background: #1421a3;
      color: #7f97ff;
      font-size: 28rem;
      cursor: pointer;
      .name {
        margin: 0 15rem;
      }
    }
    .active {
      background: #4663e1;
      color: #fff;
      border-radius: 10rem;
    }
    .resource {
      margin: 0 45rem;
      width: calc(100vw - 420rem - 50rem);
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 30rem;
      height: fit-content;
      max-height: 70vh;
      overflow-y: scroll;
      overflow-x: hidden;
      .subject-card {
        margin: 15rem 15rem 25rem;
        width: 300rem;
      }
    }
  }
}
</style>
