<template>
  <!-- 复习讲义内容单项 -->
  <div class="subject-card-container">
    <div class="subject">
      {{ content.subject }}
    </div>
    <div class="title"
         :style="{
        color: options.colorList[content.colorId].textcolor,
        background: options.colorList[content.colorId].background
      }">
      <div class="line-clamp-2">{{ content.title }}</div>
    </div>
    <div class="info-container">
      <div class="button"
           @click="handleEmit(content.id)">
        <!--  @click="handleEmit('button-click',initContent.id) -->
        {{ options.operationTitle }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

let { options, content } = defineProps({
  options: {
    type: Object,
    default: () => { }
  },
  content: {//内容列表
    type: Object,
    default: () => { }
  },
})
const emits = defineEmits(['handleEmit'])
/*defineExpose({
  initContent
})*/

//调用父元素事件
const handleEmit = (title, value) => {
  emits('handleEmit', title, value)
}

</script>

<style lang="scss" scoped>
.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 20rem;
}

.subject-card-container {
  position: relative;
  width: 300rem;
  height: 310rem;
  padding: 2.5rem;
  background: #fff;
  border-radius: 20rem;
  .subject {
    position: absolute;
    top: 13rem;
    left: -11rem;
    padding: 0rem 24rem;
    background-image: url("../../../static/card.png");
    background-size: 100% 110%;
    background-repeat: no-repeat;
    // width: 120rem;
    height: 44rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24rem;
  }
  .title {
    width: 290rem;
    height: 210rem;
    padding-top: 41rem;
    padding: 41rem 5rem 0 5rem;
    margin: 5rem auto;
    letter-spacing: 1rem;
    font-weight: bold;
    text-align: center;
    border-radius: 20rem;
    font-size: 24rem;
  }
  .info-container {
    // height: calc(100% - 210rem);
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      width: 160rem;
      height: 54rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
      font-weight: bold;
      border: 1rem solid #ccc;
      border-radius: 24rem;
      margin-top: 15rem;
      cursor: pointer;
      font-size: 28rem;
    }
  }
}
</style>
